import React from "react";
import { Button01 } from "../components/Button01";
import { Layout } from "../components/Layout/index";

const NotFoundPage: React.VFC = () => {
  return (
    <Layout title="404" description="404ページです">
      <div className="l-container py-20 text-center pc:py-32">
        <h1 className="text-[64px] font-semibold text-primary pc:text-[120px]">
          404
          <span className="block text-[24px] font-semibold leading-[56px] pc:text-[40px]">
            NOT FOUND
          </span>
        </h1>
        <p className="mt-6 text-base font-bold pc:text-2xl">
          お探しのページが見つかりません。
        </p>
        <p className="mt-8 mb-10 text-center text-base pc:mb-20">
          URL、ファイル名にタイプミスがないかご確認ください。
          <br />
          指定されたページは削除されたか、移動した可能性があります。
        </p>
        <Button01
          label="トップへ戻る"
          link="/"
          isPageTransition={true}
          isWhite={true}
        />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
